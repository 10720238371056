<template>
  <div id="create-company-page" class="flex-column">
    <div id="create-company-div">
      <div class="container d-flex h-100">
        <div class="row justify-content-center align-self-center w-100">
          <div class="text-center">
            <slot name="body">
              <component :is="currentSlide" />
            </slot>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import { mapGetters, mapActions } from 'vuex'
  import { tagSession } from '@/common/modules/inspectlet'
  export default {
    name: 'CreateCompanyPage',
    components: {
      SelectJurisdiction: () => import('@/components/StagelineV2/createCompany/SelectJurisdiction'),
      SelectEntityType:   () => import('@/components/StagelineV2/createCompany/SelectEntityType'),
      CompanyName:        () => import('@/components/StagelineV2/createCompany/CompanyName'),
      SimilarCompanyName: () => import('@/components/StagelineV2/createCompany/SimilarCompanyName'),
      ConfirmCompany:     () => import('@/components/StagelineV2/createCompany/ConfirmCompany'),
      CompanyCreated:     () => import('@/components/StagelineV2/createCompany/CompanyCreated'),
    },
    computed: {
      ...mapGetters('stagelineCreateCompany', [
        'currentSlide',
      ]),
      ...mapGetters('stageline', [
        'accountCompanies',
      ]),
    },
    mounted() {
      tagSession({ stagelineV2: 'createCompany' })

      this.resetCompanyData()
      this.setCurrentSlide('SelectJurisdiction')
      if (!this.accountCompanies?.length) this.getAccountCompanies()
    },
    methods: {
      ...mapActions('stagelineCreateCompany', [
        'resetCompanyData',
        'setCurrentSlide',
      ]),
      ...mapActions('stageline', [
        'getAccountCompanies',
      ]),
    },
  }
</script>

<style lang="scss" scoped>
  #create-company-div {
    width: 100%;
    height: 100%;
  }
  ::v-deep .btn {
    border-radius: $stageline-primary-border-radius !important;
  }
</style>
